import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';

export default function BlogPost({ data }) {
  const post = data.markdownRemark;
  return (
    <Layout>
      <div className="w-full justify-center max-w-5xl mx-auto my-8">
        <h1 className="c-h1 bg-blue-700 text-white px-4 py-4 mb-4 text-center self-stretch">
          {post.frontmatter.title}
        </h1>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>
    </Layout>
  );
}
export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
